<template>
	<div>
		<div class="timeline-items__right rounded-sm w-100 p-4 mb-5">
			<select-bot
				:size="'medium'"
				v-model="filterForm.bot_id"
				:id="filterForm.bot_id"
				:user_id="authUser.id"
				>
			</select-bot>
		</div>
		<div v-if="filterForm.bot_id">
			<vue-advanced-chat
				height="80vh"
				:show-search="false"
				:show-add-room="true"
				:current-user-id="currentUserId"
				:rooms="JSON.stringify(rooms)"
				:rooms-loaded="roomsLoaded"
				:messages="JSON.stringify(messages)"
				:messages-loaded="messagesLoaded"
				:room-actions="JSON.stringify(roomActions)"
				@open-file="openFile($event.detail[0])"
				@send-message="sendMessage($event.detail[0])"
				@fetch-messages="fetchMessages($event.detail[0])"
				@fetch-more-room="fetchMoreRooms($event.detail[0])"
				@room-action-handler="roomActionHandler($event.detail[0])"
				@rooms-list-search="roomSearch($event)"
				:styles="JSON.stringify(styles)"
			/>
		</div>

		<el-drawer
			:with-header="false"
			:visible.sync="drawerAttachClient"
			size="70%"
			ref="drawerAttachClient"
			@opened="drawerOpened('drawerAttachClientChild')"
			@closed="drawerClosed('drawerAttachClientChild')"
		>
			<div>
				<div class="app-modal__box">
					<div class="app-modal__in">
						<div class="app-modal__header d-flex f-between p-5 m-title-modal">
						<p class="large--title m-0"> {{ $t('message.attachToClient') }}</p>
						<div>
							<crm-store-update-close
								:permission="$options.name"
								:button_type="'store'"
								:loading="loadingButton"
								@c-submit="submitAttachClient(true)"
								@c-close="closeDrawer('drawerAttachClient')"
							></crm-store-update-close>
						</div>
						</div>
					</div>

					<div class="app-modal__body p-5 pb-0">
						<div class="timeline-items__right rounded-sm w-100 p-4">
							<el-form ref="form" :model="attachForm" :rules="attachFormRules" label-position="top">
								<el-row v-if="roomInfo" :gutter="20" class="mb-5">
									<el-col :span="8">
										<b>{{ $t('message.chat_id') }}</b>: {{ roomInfo.chat_id }}
									</el-col>
									<el-col :span="8">
										<b>{{ $t('message.first_name') }}</b>: {{ roomInfo.first_name }}
									</el-col>
									<el-col v-if="roomInfo.username" :span="8">
										<b>{{ $t('message.first_name') }}</b>: @{{ roomInfo.username }}
									</el-col>
								</el-row>
								<el-row :gutter="20">
									<el-col :xs="24" :sm="24" :md="12" :lg="12" :xl="12">
										<select-company
											:size="'medium'"
											:placeholder="$t('message.company')"
											:id="attachForm.company_id"
											v-model="attachForm.company_id"
										>
										</select-company>
									</el-col>
									<el-col :xs="24" :sm="24" :md="12" :lg="12" :xl="12">
										<select-client
											:size="'medium'"
											:placeholder="$t('message.client')"
											:id="attachForm.client_id"
											:company_id="attachForm.company_id"
											v-model="attachForm.client_id"
										>
										</select-client>
									</el-col>
								</el-row>
							</el-form>
						</div>
					</div>
				</div>
			</div>
        </el-drawer> 

		<el-drawer
			:with-header="false"
			:visible.sync="drawerClientShow"
			size="80%"
			class="my-bgd body_scroll_80"
			ref="drawerClientShow"
			@opened="drawerOpened('drawerClientShowChild')"
			@closed="drawerClosed('drawerClientShowChild')"
		>
			<client-show
				:selectedItem="{id: attachForm.client_id}"
				ref="drawerClientShowChild"
				drawer="drawerClientShow">
			
			</client-show>
		</el-drawer>
	</div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import selectBot from "@/components/inventory/select-telegram-bots";
import selectCompany from "@/components/selectsWithCreate/select-company-and-create";
import selectClient from "@/components/selectsWithCreate/select-company-client-and-create";
import ClientShow from "@/views/clients/components/show.vue";

import { register } from 'vue-advanced-chat'
register()

export default {
	components: {
		selectBot,
		selectCompany,
		selectClient,
		ClientShow
	},
	data() {
		return {
			drawerAttachClient: false,
			drawerClientShow: false,
			loadingButton: false,
			attachForm: {
				client_id: null,
				company_id: null,
				bot_id: null,
				chat_id: null,
			},
			currentUserId: "001",
			rooms: [],
			messages: [],
			messagesLoaded: false,
			roomsLoaded: false,
			roomParams: {
				id: '',
				chat_id: '',
				bot_id: '',
			},
			current_page: 0,
			messageWatchInterval: null,
			filterForm: {
				bot_id: '',
			},
			roomActions: [
				{
					name: 'showClient',
					title: this.$t('message.show')
				},
				{
					name: 'attachToClient',
					title: this.$t('message.attachToClient')
				},
			],
			roomInfo: null,
			attachFormRules: {
				client_id: [
					{ required: true, message: this.$t('message.please_select_input', {input: this.$t('message.client')}), trigger: 'change' },
				]
			},
			styles: {
				message: {
					backgroundReply: 'rgba(83, 168, 255, 0.4)'
				},
				footer: {
					backgroundReply: 'rgba(83, 168, 255, 0.4)'
				},
			}
		}
	},

	computed: {
        ...mapGetters({
            authUser: "auth/user",
            userList: "telegramBotMessangerUser/list",
            messageList: "telegramBotMessangerMessage/list",
            columns: "telegramBotMessangerMessage/columns",
            pagination: "telegramBotMessangerMessage/pagination",            
            filter: "telegramBotMessangerMessage/filter",
            sort: "telegramBotMessangerMessage/sort",
            unseenMessages: "telegramBotMessangerMessage/unseenMessages",
            mode: "MODE"
        })
    },

	watch: {
		"filterForm.bot_id": {
			handler: async function(newVal, oldVal) {
				await this.setNewBotConf();
				if(newVal){
					setTimeout(() => {
						this.checkUnseenMessages();
						this.updateRooms();
					}, 200);
				}
			},
			deep: true,
			immediate: true
		},
	},

	methods: {
		...mapActions({
            updateUsersList: "telegramBotMessangerUser/index",
            attachChatToClient: "telegramBotMessangerUser/attachChatToClient",
            updateMessagesList: "telegramBotMessangerMessage/index",
            storeAndSendMessage: "telegramBotMessangerMessage/store",
            setPagination: "telegramBotMessangerMessage/setPagination",
            updateSort: "telegramBotMessangerMessage/updateSort",
            updateFilter: "telegramBotMessangerMessage/updateFilter",
            updateColumn: "telegramBotMessangerMessage/updateColumn",
            updatePagination: "telegramBotMessangerMessage/updatePagination",
            show: "telegramBotMessangerMessage/show",
            empty: "telegramBotMessangerMessage/empty",
            delete: "telegramBotMessangerMessage/destroy",
            refreshData: "telegramBotMessangerMessage/refreshData",
            getUnseenMessages: "telegramBotMessangerMessage/getUnseenMessages",
        }),

		roomSearch(val){
		},

		roomActionHandler({ roomId, action }){
			this.roomInfo = this.rooms.find(el => el.roomId == roomId);
			if(this.roomInfo){
				this.attachForm.bot_id = this.filterForm.bot_id;
				this.attachForm.chat_id = roomId;
				this.attachForm.company_id = this.roomInfo.company_id;
				this.attachForm.client_id = this.roomInfo.client_id;
				switch (action.name) {
					case 'attachToClient':
						this.drawerAttachClient = true;
						break;
					case 'showClient':
						this.showClient();
						break;
				}
			}
		},

		showClient(){
			if(this.roomInfo.client_id){
				this.drawerClientShow = true;
			}else{
				this.$notify({
					title: this.$t('message.warning'),
					type: "warning",
					offset: 130,
					message: this.$t('message.client_not_attached_to_chat')
				});
			}
		},
		submitAttachClient(close){
			this.attachChatToClient(this.attachForm)
				.then(res => {
					this.loadingButton = false;
					this.$alert(res);
					if (close) this.closeDrawer('drawerAttachClient');
				})
				.catch(err => {
					this.loadingButton = false;
					this.$alert(res);
				});
		},

		openFile({ file }) {
			window.open(file.file.url, '_blank')
		},

		setNewBotConf()
		{
			this.roomParams.id = '';
			this.roomParams.chat_id = '';
			this.roomParams.bot_id = this.filterForm.bot_id;
			this.rooms = [];
			this.messages = [];
			this.messagesLoaded = false;
			this.roomsLoaded = false;
			this.current_page = 0;
			this.messageWatchInterval = null;
		},

		updateRooms(){
			this.updateUsersList(this.filterForm)
				.then(res => {
					let new_rooms = JSON.parse(JSON.stringify(this.userList));
					this.rooms = [...new_rooms];
					this.roomsLoaded = true;
				})
				.catch(err => {
					
				});
		},

		checkUnseenMessages() {
            this.messageWatchInterval = setInterval(() => {
				if(this.roomParams.id && this.roomParams.chat_id){
					this.updateRooms();
					this.getUnseenMessages({...this.roomParams, ...this.filterForm})
						.then(res => {
							if (this.unseenMessages.length > 0) {
								this.messages = [...this.messages, ...this.unseenMessages];
								let lastElement = this.unseenMessages.slice(-1);
								this.roomParams.id = lastElement[0] ? lastElement[0].id : '';
								this.roomParams.chat_id = this.roomParams.chat_id
								this.roomParams.id = lastElement[0] ? lastElement[0].id : ''
							}
						})
						.catch(err => {
							console.log(err, 'err unseenMessages');
						});
				}
                
            }, 6000); // every 10 seconds
        },

		fetchMoreRooms({ options = {} }) {
			this.updateUsersList({})
				.then(res => {
					this.rooms = JSON.parse(JSON.stringify(this.userList));
					this.rooms = [...this.rooms];
				})
				.catch(err => {
					
				});
		},
		fetchMessages({ room, options = {} }) {
			if(room && room.roomId){
				if(options.reset){
					this.messages = [];
					this.current_page = 1;
				}else{
					++this.current_page; 
				}
				// this.resetRoomMessageCount(room.roomId);
				let query = {chat_id: room.roomId, per_page: 30, page: this.current_page, ...this.filterForm};
				this.updateMessagesList(query)
					.then(res => {
						let messages = JSON.parse(JSON.stringify(this.messageList));
						setTimeout(() => {
							this.addMessages(messages, room.roomId, options.reset);
							// this.messages = [...this.messages];
							if (this.pagination && this.messages.length >= this.pagination.total) {
								this.messagesLoaded = true;
							}
						}, 200)
					})
					.catch(err => {
						
					})
			}

		},

		resetRoomMessageCount(roomId){
			if(this.rooms.length > 0 && roomId){
				let room_index = this.rooms.findIndex(el => el.roomId == roomId);
				if(this.rooms[room_index]){
					this.rooms[room_index].unreadCount = 0;
					this.rooms = [...this.rooms]
				}
			}
		},

		addMessages(messages, roomId, reverse = false) {
			if(reverse){
				this.messages = [...this.messages, ...messages];
			}else{
				this.messages = [...messages, ...this.messages];
			}
			let lastElement = this.messages.slice(-1);
			this.updateRoomParams(lastElement[0], roomId);
		},

		sendMessage(message) {
			let content ='';
			if(message.files && message.files[0]){
				switch (true) {
					case message.files[0].type.includes('audio'):
						content = this.$t('message.voice');
					case message.files[0].type.includes('image'):
						content = this.$t('message.image');
					case message.files[0].type.includes('video'):
						content = this.$t('message.video');
					default:
						content = this.$t('message.document');
				}
			}
			let new_message = {
				content: message.content ? message.content : content,
				chat_id: message.roomId,
				bot_id: this.filterForm.bot_id,
				reply_id: message.replyMessage ? message.replyMessage.id : null,
				reply_to_message_id: message.replyMessage ? message.replyMessage._id : null
			}
			
			let formData = new FormData();
			for (const key in new_message) {
				if (new_message[key]) {
					formData.append(key, new_message[key]);
				} else {
					formData.append(key, '');
				}
          	}
			if (message.files && message.files[0] && _.size(message.files[0]) > 0) {
				let file = message.files[0];
				for (const key in file) {
					if (file.hasOwnProperty(key)) {
						const element = file[key];
						formData.append(`file[${key}]`, element);
					}
				}
          	}
			
			this.storeAndSendMessage(formData)
				.then(res => {
					let new_message = res.data.result.data.telegramBotMessangerMessage;
					if(new_message){
						this.messages = [
							...this.messages,
							new_message
						];
						this.updateRoomParams(new_message, message.roomId);
					}
				})
				.catch(err => {
					console.log(err, 'errerrerr');
				})
		},

		updateRoomParams(last_message, chat_id){
			this.roomParams.id = last_message ? last_message.id : '';
			this.roomParams.chat_id = chat_id
			this.roomParams.bot_id = this.filterForm.bot_id;
		},

		closeDrawer(drawer) {       
			if (this.$refs[drawer] && _.isFunction(this.$refs[drawer].closeDrawer)) {
				this.$refs[drawer].closeDrawer();
			}
		},
		drawerClosed(ref) {
			if (this.$refs[ref]) {
				this.$refs[ref].closed()
			}
			if (_.isFunction(this.empty)) {
				this.empty()
			}
		},
		drawerOpened(ref) {        
			if (this.$refs[ref]) {
				if (_.isFunction(this.$refs[ref].opened)) {
					this.$refs[ref].opened()
				}
			}
		},
		
	},
	beforeRouteLeave(to, from, next) {
		// Clean up the interval before leaving the route
		this.roomParams.id = '';
		this.roomParams.chat_id = '';
		setTimeout(() => {
			next();
		}, 50);
	},
}
</script>

<style lang="scss">
body {
	font-family: 'Quicksand', sans-serif;
}
</style>